<template>
  <section id="user-profile">
    <b-overlay :show="loading" no-center variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner/>
      </template>
      <profile :user="user" :not-loaded="notLoaded" :owner="true" @open-modal-gallery="openModalGallery"
               @setLoading="setLoading($event)" @setUser="setUser($event)" @getUser="getMe()" @getMe="getMe()"/>
    </b-overlay>
    <b-overlay :show="loading" no-center variant="transparent" rounded="sm" style="z-index: 999999">
      <template #overlay>
        <loading-spinner/>
      </template>
      <b-modal v-model="modalGallery" hide-header-close hide-header hide-footer size="xl">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="modal-title">
            {{ $t('Edit your medias') }}
          </h3>
          <b-img :src="iconClose" width="18" class="cursor-pointer" @click="modalGallery = false" />
        </div>
        <b-tabs content-class="min-height-80" v-model="activeTabIndex">
          <b-tab :title="$t('Public')">
            <h2>{{ $t('Photos') }}</h2>
            <b-row class="gallery" gap="20" v-viewer>
              <b-col v-for="image in publicImages" :key="image.id" class="mb-1" xl="2" lg="3" md="4" sm="6" cols="6">
                <div class="image-card with-image-buttons">
                  <div class="image">
                    <b-img :src="image.secureUrl" fluidGrow class="cursor-pointer" />
                    <span class="remove-item" @click="!loading ? removeImage(image) : null">
                      <b-img :src="iconCloseRed" width="22" :data-disabled="true"/>
                    </span>
                    <div class="image-buttons">
                      <span v-if="profileImageId === image.id" class="profile-photo-button image-card-button">
                        {{ $t('Profile photo') }}
                      </span>
                      <span v-if="!image.approved" class="pending-button image-card-button">
                        {{ $t('Waiting for approval') }}
                      </span>
                    </div>
                  </div>
                  <div class="make-profile-photo">
                    <div class="make-profile-photo-button image-card-button"
                         :class="{disabled: profileImageId === image.id || !image.approved}"
                         @click="profileImageId !== image.id && image.approved && !loading ? setUserProfileImage(image) : null">
                      {{ $t('Make profile photo') }}
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col class="mb-1" xl="2" lg="3" md="4" sm="6" cols="6">
                <div class="image-card">
                  <div class="image">
                    <div class="grey-bg" @click="!loading ? launchFilePicker('inputPublicImage') : null">
                      <input type="file" @change="addedImage" style="display:none" ref="inputPublicImage"
                             accept="image/*">
                      <span class="add-media-text">
                        {{ $t('Add photo') }}
                      </span>
                      <span class="plus-icon">
                        <font-awesome-icon icon="fa-solid fa-plus-circle" size="2x" beat
                                           style="color: #F9447A; background-color: #fff; border-radius: 50%"/>
                      </span>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab v-if="false" :title="$t('Private Photos and Videos')">
            <h2>{{ $t('Photos') }}</h2>
            <b-row class="gallery" gap="20" v-viewer>
              <b-col v-for="image in hiddenImages" :key="image.id" class="mb-1" xl="2" lg="3" md="4" sm="6" cols="6">
                <div class="image-card">
                  <div class="image">
                    <b-img :src="image.secureUrl" fluidGrow class="cursor-pointer" />
                    <span class="remove-item" @click="!loading ? removeImage(image) : null">
                      <b-img :src="iconCloseRed" width="22" :data-disabled="true"/>
                    </span>
                    <div class="image-buttons">
                      <span v-if="profileImageId === image.id" class="profile-photo-button image-card-button">
                        {{ $t('Profile photo') }}
                      </span>
                      <span v-if="!image.approved" class="pending-button image-card-button">
                        {{ $t('Waiting for approval') }}
                      </span>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col class="mb-1" xl="2" lg="3" md="4" sm="6" cols="6">
                <div class="image-card ">
                  <div class="image">
                    <div class="grey-bg" @click="!loading ? launchFilePicker('inputHiddenImage') : null">
                      <input type="file" @change="addedImage($event, 'hidden')" style="display:none"
                             ref="inputHiddenImage" accept="image/*">
                      <span class="add-media-text">
                        {{ $t('Add photo') }}
                      </span>
                      <span class="plus-icon">
                        <font-awesome-icon icon="fa-solid fa-plus-circle" size="2x" beat
                                           style="color: #F9447A; background-color: #fff; border-radius: 50%"/>
                      </span>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <h2>{{ $t('Videos') }}</h2>
            <b-row class="gallery" gap="20">
              <b-col v-for="(video, index) in hiddenVideos" :key="video.id" class="mb-1" xl="2" lg="3" md="4" sm="6" cols="6">
                <div class="image-card">
                  <div class="image">
                    <b-img :src="video.thumbnailUrl" fluidGrow />
                    <div class="private-video-play-icon" @click="$bvModal.show('modal-private-video-inner-' + index)">
                      <font-awesome-icon icon="fa-solid fa-play" size="3x" class="color-white"/>
                    </div>
                    <b-modal :id="'modal-private-video-inner-' + index" modal-class="modal-private-video" size="lg"
                             centered hide-header-close hide-footer hide-header>
                      <video width="100%" controls>
                        <source :src="video.secureUrl" type="video/mp4">
                      </video>
                    </b-modal>
                    <span class="remove-item" @click="!loading ? removeVideo(video) : null">
                      <b-img :src="iconCloseRed" width="22" />
                    </span>
                    <div class="image-buttons">
                      <span v-if="!video.approved" class="pending-button image-card-button">
                        {{ $t('Waiting for approval') }}
                      </span>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col class="mb-1" xl="2" lg="3" md="4" sm="6" cols="6">
                <div class="image-card ">
                  <div class="image">
                    <div class="grey-bg" @click="!loading ? launchFilePicker('inputHiddenVideo') : null">
                      <input type="file" @change="addedVideo" style="display:none" ref="inputHiddenVideo"
                             accept="video/*">
                      <span class="add-media-text">
                        {{ $t('Add video') }}
                      </span>
                      <span class="plus-icon">
                        <font-awesome-icon icon="fa-solid fa-plus-circle" size="2x" beat
                                           style="color: #F9447A; background-color: #fff; border-radius: 50%"/>
                      </span>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-modal>
    </b-overlay>
  </section>
</template>

<script>
import {
  BAlert, BCard, BCardBody, BCarousel, BCarouselSlide, BCol, BImg, BOverlay, BRow, BTab, BTabs
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { throwDefaultError, showNotify } from '@/helpers/helpers'
import Profile from '@/components/Profile'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'profile.index',
  components: {
    BAlert,
    BCard,
    BCardBody,
    BCarousel,
    BCarouselSlide,
    BCol,
    BImg,
    BOverlay,
    BRow,
    BTab,
    BTabs,

    Profile,

    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      activeTabIndex: 0,
      confirmModalOptions: {
        title: 'Onay',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Sil',
        cancelTitle: 'Vazgeç',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      },
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" }
      },
      iconClose: require('@/assets/images/icons/close-icon.svg'),
      iconCloseRed: require('@/assets/images/icons/close-icon-red.svg'),
      id: null,
      loading: false,
      medias: [],
      modalGallery: false,
      notLoaded: false,
      user: null,
    }
  },
  watch: {
    modalGallery(val) {
      if (val) {
        this.getMedias()
      }
    }
  },
  created() {
    this.getMe()
  },
  mounted() {
    // const file = { size: 123, name: "Icon", type: "image/jpg" };
    // const url = "https://nikonrumors.com/wp-content/uploads/2014/03/Nikon-1-V3-sample-photo.jpg";
    // this.$refs.myVueDropzone.manuallyAddFile(file, url);
  },
  computed: {
    hiddenImages() {
      return this.medias.filter(media => media.type === 1 && media.hidden)
    },
    hiddenVideos() {
      return this.medias.filter(media => media.type === 2 && media.hidden)
    },
    images() {
      return this.medias.filter(media => media.type === 1)
    },
    profileImageId() {
      return this.$store.getters['auth/profileImageId']
    },
    publicImages() {
      return this.medias.filter(media => media.type === 1 && !media.hidden)
    },
    publicVideos() {
      return this.medias.filter(media => media.type === 2 && !media.hidden)
    },
    videos() {
      return this.medias.filter(media => media.type === 2)
    },
  },
  methods: {
    addedImage(event, type = 'public') {
      const formData = new FormData()
      formData.append('file', event.target.files[0])

      this.loading = true
      useJwt.uploadImage(formData)
        .then(response => {
          if (type === 'hidden') {
            useJwt.toggleImageStatus(response.data.body.id)
              .then(() => {
                this.getMedias()
                this.getMe()
              })
              .catch(error => {
                throwDefaultError(this.$toast, error)
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            this.getMedias()
            this.getMe()
            this.loading = false
          }
        })
        .catch(error => {
          throwDefaultError(this.$toast, error)
          this.loading = false
        })
        .finally(() => {
          event.target.value = ''
        })
    },
    addedVideo(event, type = 'public') {
      const formData = new FormData()
      formData.append('file', event.target.files[0])

      this.loading = true
      useJwt.uploadVideo(formData)
        .then(response => {
          this.getMedias()
          this.getMe()
        })
        .catch(error => {
          throwDefaultError(this.$toast, error)
          this.loading = false
        })
        .finally(() => {
          this.loading = false
          event.target.value = ''
        })
    },
    getMe() {
      this.loading = true

      useJwt.getMe()
          .then(response => {
            try {
              this.user = response.data.body
              this.id = response.data.body.id

              this.$store.dispatch('auth/updateUser', this.user)
            } catch (e) {
              throwDefaultError(this.$toast)
              this.notLoaded = true
            }
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
            this.notLoaded = true
          })
          .finally(() => {
            this.loading = false
          })
    },
    getMedias() {
      this.loading = true

      useJwt.getMedias(this.$store.getters['auth/userId'])
          .then(response => {
            try {
              this.medias = response.data.body
            } catch (e) {
              throwDefaultError(this.$toast)
            }
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },
    launchFilePicker(name) {
      this.$refs[name].click();
    },
    openModalGallery() {
      this.modalGallery = true
    },
    removeImage(image) {
      this.$bvModal.msgBoxConfirm(this.$t('Are you sure you want to delete?'), this.confirmModalOptions)
          .then(value => {
            if (value) {
              this.loading = true

              useJwt.removeImage(image.id)
                  .then(response => {
                    this.getMe()
                    this.getMedias()
                    showNotify(this.$toast, this.$t('Photo deleted successfully'), 'success')
                  })
                  .catch(error => {
                    throwDefaultError(this.$toast, error)
                  })
                  .finally(() => { this.loading = false })
            }
          })
    },
    removeVideo(video) {
      this.$bvModal.msgBoxConfirm(this.$t('Are you sure you want to delete?'), this.confirmModalOptions)
          .then(value => {
            if (value) {
              useJwt.removeVideo(video.id)
                  .then(response => {
                    this.getMe()
                    this.getMedias()
                    showNotify(this.$toast, this.$t('Video deleted successfully'), 'success')
                  })
                  .catch(error => {
                    throwDefaultError(this.$toast, error)
                  })
                  .finally(() => { this.loading = false })
            }
          })
    },
    setLoading($event) {
      this.loading = $event
    },
    setUser($event) {
      this.user = $event
    },
    setUserProfileImage(image) {
      if (this.profileImageId !== image.id && image.approved) {
        this.$bvModal.msgBoxConfirm(this.$t('Are you sure you want to make a profile picture?'), {
          ...this.confirmModalOptions,
          okVariant: 'success',
          okTitle: this.$t('Make profile photo'),
        })
            .then(value => {
              if (value) {
                this.loading = true

                useJwt.setUserProfileImage(image.id)
                    .then(response => {
                      this.getMe()
                      showNotify(this.$toast, this.$t('Profile photo changed successfully') + '.', 'success')
                    })
                    .catch(error => {
                      throwDefaultError(this.$toast, error)
                    })
                    .finally(() => { this.loading = false })
              }
            })

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.b-overlay {
  z-index: 999999;
}
.image {
  position: relative;
  .private-video-play-icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: zoom-in;
  }
}
</style>
