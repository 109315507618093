<template>
  <section id="user-profile">
    <b-overlay :show="loading" no-center variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner/>
      </template>
      <b-row v-if="!notLoaded">
        <b-col class="d-flex justify-content-between my-1" sm="12">
          <div class="back-button cursor-pointer" @click="back">
            <font-awesome-icon icon="fa-solid fa-arrow-left" size="lg"/>
          </div>
          <div v-if="!owner && user" class="profile-menu cursor-pointer">
            <div class="dropdown">
              <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  right
              >
                <template #button-content>
                  <feather-icon
                      icon="MoreVerticalIcon"
                      size="17"
                      class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item v-if="!user.blockedByUser" @click="blockUser">
                  {{ $t('Block user') }}
                </b-dropdown-item>
                <b-dropdown-item v-else @click="unBlockUser">
                  {{ $t('Unblock') }}
                </b-dropdown-item>
                <b-dropdown-item @click="showReportModal">
                  {{ $t('Report') }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </b-col>
        <b-col v-if="user" md="6" sm="12" xs="12">
          <b-carousel
              id="user-profile-carousel"
              v-model="slide"
              :interval="4000"
              controls
              indicators
              fade
              style="text-shadow: 1px 1px 2px #333;"
              @sliding-start="onSlideStart"
              @sliding-end="onSlideEnd"
          >
            <!-- Slides with image only -->
            <b-carousel-slide v-for="image in userImages"
                              :key="'carousel-' + image.id"
                              :img-src="image.secureUrl">
              <template #img>
                <div class="img-overlay">
                  <b-img
                      fluidGrow
                      :src="image.secureUrl"
                      :alt="user.userName"
                      class="slide-image"
                  />
                </div>
              </template>
            </b-carousel-slide>
            <div v-if="owner" class="gallery-edit-icon cursor-pointer">
              <b-img :src="iconProfileEditWhite" height="32" width="32" @click="$emit('open-modal-gallery')"/>
            </div>
          </b-carousel>
        </b-col>
        <b-col v-if="user" md="6" sm="12" xs="12">
          <b-card>
            <b-card-body>
              <div class="online-status">
                <status-indicator :status="user.profile.onlineStatus" class="online-status" />
              </div>
              <div class="user-information">
                <b-row>
                  <b-col v-if="user" xl="12" lg="12" md="12" sm="12" xs="12">
                    <div class="name-location">
                      <div class="title">
                        {{ user.userName }}<span v-if="user.profile.year">, {{ new Date().getFullYear() - user.profile.year }}</span>
                      </div>
                      <div class="location">
                        <b-img :src="iconLocation"/>
                        {{ user.profile.country }}
                      </div>
                    </div>
                  </b-col>
                  <b-col v-if="user" xl="12" lg="12" md="12" sm="12" xs="12">
                    <div v-if="!owner" class="actions">
                      <div class="heart">
                        <b-img v-if="user.fav" :src="iconHeart" height="24" width="24" @click="unlike"/>
                        <b-img v-else :src="iconHeartEmpty" height="24" width="24" @click="like"/>
                      </div>
                      <router-link :to="{name: 'chat', params: {userId: user.id}}">
                        <div class="chat" @click="gtmGoChat">
                          <b-img :src="iconChat" height="24" width="24"/>
                        </div>
                      </router-link>
                      <div class="phone ">
                        <b-img :src="iconPhone" height="24" width="24" @click="call"/>
                      </div>
                      <div v-if="user.profile.cameraOn" class="video-call">
                        <b-img :src="iconVideoCall2" height="24" width="24" @click="videoCall"/>
                      </div>
                      <div class="gift" id="gifts-popover">
                        <b-img :src="iconGift" height="24" width="24" @click="chooseGiftModal = true"/>
<!--                        <b-popover target="gifts-popover" placement="topright" custom-class="gifts-popover"-->
<!--                                   :show.sync="showGifts" triggers="click">-->
<!--                          <div v-for="gift in gifts" :key="gift.productId" class="gift">-->
<!--                            <b-img width="64" :alt="gift.label"-->
<!--                                   :src="getImgGift(gift.image)" class="gift-image" @click="showGiftModal(gift)"/>-->
<!--                            <p v-if="userGender === 'male'">-->
<!--                              {{ gift.credits }} {{ $t('Diamonds') }}-->
<!--                            </p>-->
<!--                          </div>-->
<!--                        </b-popover>-->
                      </div>
                    </div>
                  </b-col>
                </b-row>
<!--                <div v-if="owner" class="profile-edit-icon cursor-pointer mr-1">-->
<!--                  <router-link :to="{name: 'settings.profile-information'}">-->
<!--                    <b-img :src="iconProfileEdit" height="24" width="24"/>-->
<!--                  </router-link>-->
<!--                </div>-->
              </div>
              <div class="about-me">
                <div class="d-flex justify-content-between head">
                  <span>
                    {{ $t('About me') }}
                  </span>
                  <div v-if="owner" class="profile-edit-icon cursor-pointer">
                    <router-link :to="{name: 'settings.profile-information'}">
                      <b-img :src="iconProfileEdit" height="24" width="24"/>
                    </router-link>
                  </div>
                </div>
                <p v-if="owner">
                  {{ user.profile.description && user.profile.description !== ''
                    ? user.profile.description
                    : $t('You haven\'t added any information about me yet') }}
                </p>
                <p v-else>
                  {{ user.profile.description && user.profile.description !== ''
                    ? user.profile.description
                    : $t('User has not added information about') }}
                </p>
                <span v-if="false" class="link">
                  {{ $t('Read more') }}
                </span>
              </div>
              <b-row>
                <b-col v-if="user && (owner || user.profile.gender === 'male')" xl="8" offset-xl="2" offset-lg="2" lg="8" md="12" sm="12" xs="12">
                  <div class="user-level">
                    <div class="user-level-member" :class="{passive: checkBadge('member')}">
                      <div>
                        <img :src="iconLevelMember" width="36" />
                        <span class="bar"></span>
                      </div>
                      <div class="text">
                        BOHO <br>
                        MEMBER
                      </div>
                    </div>
                    <div class="user-level-bronze" :class="{passive: checkBadge('bronze')}">
                      <div>
                        <img :src="iconLevelBronze" width="36" />
                        <span class="bar"></span>
                      </div>
                      <div class="text">
                        BRONZE
                      </div>
                    </div>
                    <div class="user-level-silver" :class="{passive: checkBadge('silver')}">
                      <div>
                        <img :src="iconLevelSilver" width="36" />
                        <span class="bar"></span>
                      </div>
                      <div class="text">
                        SILVER
                      </div>
                    </div>
                    <div class="user-level-gold" :class="{passive: checkBadge('gold')}">
                      <div>
                        <img :src="iconLevelGold" width="36" />
                        <span class="bar"></span>
                      </div>
                      <div class="text">
                        GOLD
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <div v-if="false" class="images">
                <div class="d-flex justify-content-between title mb-2">
                  <span>
                   {{ $t('Special Photos')}}
                  </span>
                  <div v-if="owner" class="cursor-pointer mr-1">
                    <b-img :src="iconProfileEdit" height="24" width="24" @click="$emit('open-modal-gallery')"/>
                  </div>
                </div>
                <b-row v-if="privateImages.length > 0" class="private-images" v-viewer>
                  <b-col v-for="(image, index) in privateImages" :key="'private-image-' + image.id"
                         md="3" sm="6" cols="6" v-show="privateImagesShowStatus || index < 3"  class="mb-1">
                    <div class="img-overlay cursor-pointer" @click="buyMediaModal(image)">
                      <b-img fluid :src="image.secureUrl"/>
                    </div>
                  </b-col>
                  <b-col v-if="privateImages.length > 3" class="text-center plus" md="3" sm="6" xs="12">
                    <span v-if="!privateImagesShowStatus" @click="showPrivateImages">
                      +{{ privateImages.length - 3 }}
                    </span>
                  </b-col>
                </b-row>
                <b-alert
                    v-else
                    variant="primary"
                    show>
                  <div class="alert-body">
                    <span v-if="owner">
                      {{ $t('You haven\'t added any photos yet') }}
                    </span>
                    <span v-else>
                      {{ $t('User has not uploaded photos yet') }}
                    </span>
                  </div>
                </b-alert>
              </div>
              <div v-if="false" class="videos">
                <div class="d-flex justify-content-between title mb-2">
                  <span>
                    {{ $t('Special Videos')}}
                  </span>
                  <div v-if="owner" class="cursor-pointer mr-1">
                    <b-img :src="iconProfileEdit" height="24" width="24" @click="$emit('open-modal-gallery')"/>
                  </div>
                </div>
                <b-row v-if="privateVideos.length > 0" class="private-videos" v-viewer>
                  <b-col v-for="(video, index) in privateVideos" :key="video.id" md="3" sm="6" cols="6"
                         v-show="index < 2 || privateVideosShowStatus" class="mb-1">
                    <div class="img-overlay cursor-pointer"
                         @click="!video.thumbnailUrl.includes('blur') ? $bvModal.show('modal-private-video-' + index) : buyMediaModal(video)">
                      <b-img fluid :src="video.thumbnailUrl"/>
                    </div>
                    <div class="private-video-play-icon"
                         @click="!video.thumbnailUrl.includes('blur') ? $bvModal.show('modal-private-video-' + index) : buyMediaModal(video)">
                      <font-awesome-icon icon="fa-solid fa-play" size="3x" class="color-white"/>
                    </div>
                    <b-modal v-if="!video.thumbnailUrl.includes('blur')" :id="'modal-private-video-' + index"
                             modal-class="modal-private-video"
                             size="lg" centered hide-header-close hide-footer hide-header>
                      <b-embed type="video" aspect="4by3" controls :poster="video.thumbnailUrl">
                        <source :src="video.secureUrl" type="video/mp4">
                      </b-embed>
                    </b-modal>
                  </b-col>
                  <b-col v-if="privateVideos.length > 2" class="text-center plus" md="3" sm="6" cols="6">
                    <span v-if="!privateVideosShowStatus"
                          @click="!owner ? showPrivateVideos() : privateVideosShowStatus = true">
                      +{{ privateVideos.length - 2 }}
                    </span>
                  </b-col>
                </b-row>
                <b-alert
                    v-else
                    variant="primary"
                    show>
                  <div class="alert-body">
                    <span v-if="owner">
                      {{ $t('You haven\'t added any videos yet') }}
                    </span>
                    <span v-else>
                      {{ $t('User has not uploaded video yet') }}
                    </span>
                  </div>
                </b-alert>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-alert
          v-else
          variant="primary"
          show>
        <div class="alert-body">
          <span>{{ $t('User not found') }}!</span>
        </div>
      </b-alert>

      <b-modal v-if="selectedGift" v-model="giftModal" centered hide-header-close hide-footer size="sm"
               :title="$t('Gift for {name}', {name: user.userName})">
        <div class="gifts-modal">
          <h4>{{ selectedGift.label }}</h4>
          <b-img :src="getImgGift(selectedGift.image)" width="128" :alt="selectedGift.label" />
          <h4 v-if="userGender === 'male'">
            {{ numberFormat(selectedGift.credits) }}
            <b-img :src="iconDiamonds1" width="16" height="16" style="margin-top: -2px" />
          </h4>
          <b-button variant="primary" block @click="sendGift(selectedGift)" :disabled="loading">
            <b-spinner v-if="loading" small></b-spinner>
            {{ $t('Send') }}
          </b-button>
          <b-button variant="outline-dark" size="sm" @click="hideGiftModal(true)" :disabled="loading">
            {{ $t('Choose Another Gift') }}
          </b-button>
        </div>
      </b-modal>

      <b-modal v-if="gifts.length > 0" v-model="chooseGiftModal"
               body-class="choose-gift-modal-body" scrollable hide-footer size="lg">
        <div class="choose-gift-modal" >
          <div v-for="gift in gifts" :key="gift.productId" class="gift">
            <b-img width="64" :alt="gift.label"
                   :src="getImgGift(gift.image)" class="gift-image" @click="showGiftModal(gift)"/>
            <p v-if="userGender === 'male'">
              {{ numberFormat(gift.credits) }}
              <b-img :src="iconDiamonds1" width="16" height="16" style="margin-top: -2px" />
            </p>
          </div>
        </div>
      </b-modal>

      <b-modal v-if="user" v-model="modalReportUser" :title="$t('Report {name}', {name: user.userName})"
               centered hide-header-close hide-footer>
        <div>
          <validation-observer
              v-if="reportReasonTypes.length"
              ref="reportForm"
              #default="{invalid}"
          >
            <b-form
                class="mt-2"
                @submit.prevent
            >

              <b-form-group
                  label-for="reason"
                  :label="$t('Report reason')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Report reason')"
                    vid="reason"
                    rules="required"
                >
                  <v-select
                      v-model="report.reason"
                      :clearable="false"
                      :disabled="loading"
                      :label="$t('Report reason')"
                      :options="reportReasonTypes"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                  label-for="reportMessage"
                  :label="$t('Your message')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Your message')"
                    vid="description"
                    rules="required"
                >
                  <b-form-textarea
                      id="description"
                      :disabled="loading"
                      v-model="report.description"
                      :placeholder="$t('Your message')"
                      rows="3"
                      max-rows="6"
                  ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                  variant="primary"
                  class="mt-2 px-4"
                  :disabled="loading"
                  @click="reportUser"
              >
                <b-spinner v-if="loading" small></b-spinner>
                {{ $t('Report') }}
              </b-button>
            </b-form>
          </validation-observer>
        </div>
      </b-modal>

      <b-modal v-if="creditsMedia" v-model="modalBuyMedia" centered hide-header-close hide-footer size="sm"
               :title="$t('Buy media')">
        <div class="gifts-modal">
          <h4 v-if="creditsMedia.type == 1" class="text-center">
            {{ $t('Buy photo notification message', {name: user.userName, credits: creditsPhoto.credits}) }}
          </h4>
          <h4 v-else class="text-center">
            {{ $t('Buy video notification message', {name: user.userName, credits: creditsVideo.credits}) }}
          </h4>
          <b-button variant="primary" block @click="buyMedia(creditsMedia)" :disabled="loading">
            {{ $t('Buy') }}
          </b-button>
          <b-button variant="outline-dark" size="sm" @click="modalBuyMedia = false; creditsMedia = null; "
                    :disabled="loading">
           {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-modal>
    </b-overlay>
  </section>
</template>

<script>
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'viewerjs/dist/viewer.css'
import {
  BAlert, BButton, BCard, BCardBody, BCarousel, BCarouselSlide, BCol, BDropdown, BDropdownItem, BEmbed, BForm,
  BFormGroup, BFormTextarea, BImg, BModal, BOverlay, BPopover, BRow, BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import LoadingSpinner from '@/components/LoadingSpinner'
import StatusIndicator from '@/components/StatusIndicator'
import { gtmTrackNewEvent, showNotify, throwDefaultError, numberFormat } from '@/helpers/helpers'
import store from "@/store";
import vSelect from 'vue-select'

// import { getUser } from '@/auth/utils'

export default {
  props: {
    notLoaded: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    owner: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    BAlert,
    BButton,
    BCard,
    BCardBody,
    BCarousel,
    BCarouselSlide,
    BCol,
    BDropdown,
    BDropdownItem,
    BEmbed,
    BForm,
    BFormGroup,
    BFormTextarea,
    BImg,
    BModal,
    BOverlay,
    BPopover,
    BRow,
    BSpinner,

    LoadingSpinner,
    StatusIndicator,

    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  data() {
    return {
      chooseGiftModal: false,
      creditsMedia: null,
      giftModal: false,
      gifts: [],
      iconChat: require('@/assets/images/icons/chat-blue.svg'),
      iconDiamonds1: require('@/assets/images/icons/diamonds-1.svg'),
      iconGift: require('@/assets/images/icons/gift.svg'),
      iconHeart: require('@/assets/images/icons/heart.svg'),
      iconHeartEmpty: require('@/assets/images/icons/heart-empty.svg'),
      iconLevelBronze: require('@/assets/images/levels/bronze.png'),
      iconLevelGold: require('@/assets/images/levels/gold.png'),
      iconLevelMember: require('@/assets/images/levels/member.png'),
      iconLevelSilver: require('@/assets/images/levels/silver.png'),
      iconLocation: require('@/assets/images/icons/location.svg'),
      iconPhone: require('@/assets/images/icons/phone-green.svg'),
      iconProfileEdit: require('@/assets/images/icons/profile-edit.svg'),
      iconProfileEditWhite: require('@/assets/images/icons/profile-edit-white.svg'),
      iconVideoCall2: require('@/assets/images/icons/video-call2.svg'),
      loading: false,
      modalBuyMedia: false,
      modalReportUser: false,
      multimediaCredits: [],
      notLoadedImage: require('@/assets/images/pages/error-dark.svg'),
      privateImage: require('@/assets/images/profile/private-image.jpeg'),
      privateImagesShowStatus: false,
      privateVideosShowStatus: false,
      report: {
        reason: '',
        description: '',
      },
      selectedGift: null,
      showGifts: false,
      slide: 0,
      sliding: null,
    }
  },
  created() {
    this.getGifts()
    // this.getMultimediaCredits()

    if (this.$route.params?.type === 'gifts') {
      this.chooseGiftModal = true
    }
  },
  computed: {
    creditsPhoto() {
      return Array.isArray(this.multimediaCredits) ? this.multimediaCredits.find(credit => credit.productType === 'user_photo') : null
    },
    creditsVideo() {
      return Array.isArray(this.multimediaCredits) ? this.multimediaCredits.find(credit => credit.productType === 'user_video') : null
    },
    privateImages() {
      if (this.user && this.user.images && Array.isArray(this.user.images)) {
        return this.user.images.filter(i => i.hidden)
      }
      return []
    },
    privateVideos() {
      if (this.user && this.user.videos && Array.isArray(this.user.videos)) {
        return this.user.videos.filter(i => i.hidden)
      }
      return []
    },
    reportReasonTypes() {
      return this.$store.getters['app/reportReasonTypes']
    },
    userGender() {
      return this.$store.getters['auth/userGender']
    },
    userImages() {
      if (this.user) {
        return [
          {
            id: this.user.profile.imageUuid,
            secureUrl: this.user.profile.image
          }
        ].concat(this.user.images ? this.user.images.filter(i => !i.hidden && i.id !== this.user.profile.imageUuid) : [])
      }
      return []
    },
  },
  methods: {
    back() {
      this.$router.back()
    },
    blockUser() {
      this.$emit('setLoading', true)
      useJwt.blockUser(this.user.id)
          .then(response => {
            showNotify(this.$toast, this.$t('User blocking successful'), 'success')
            // this.$emit('setUser', {
            //   ...this.user,
            //   fav: true
            // })

            gtmTrackNewEvent('blockuser', {
              category: 'Profil',
              action: 'BlockUser',
              label: this.user.id
            })
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.$emit('setLoading', false) })
    },
    buyMedia(media) {
      this.$emit('setLoading', true)
      useJwt.sendGift({
        targetUserUuid: this.user.id,
        productId: media.type == 1 ? this.creditsPhoto.productId : this.creditsVideo.productId,
      })
          .then(response => {
            showNotify(this.$toast, this.$t('Media buying successful'), 'success')
            this.$emit('getUser')
            this.$emit('getMe')
            this.modalBuyMedia = false
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => {
            this.$emit('setLoading', false)
          })
    },
    buyMediaModal(media) {
      if (media.type == 1 && !media.secureUrl.includes('blur')) {
        const viewer = this.$el.querySelector('.private-images').$viewer
        viewer.show()
        return true
      }
      if (!media.hidden || (media.type == 1 && !this.creditsPhoto) || (media.type == 2 && !this.creditsVideo)) {
        return true
      }

      this.creditsMedia = media
      this.modalBuyMedia = true
    },
    call() {
      this.$store.dispatch('app/setModalCallUser', this.user)
      this.$store.dispatch('app/setModalCall', true)
      // if (this.user?.profile?.callType == 2) {
      //   this.$store.dispatch('app/setModalDownloadMobileApp', true)
      // } else {
      //   if (!this.$store.getters['auth/userPhoneNumber']) {
      //     this.$store.dispatch('app/setModalAddPhoneNumberShowDiamond', false)
      //     this.$store.dispatch('app/setModalAddPhoneNumber', true)
      //     this.$store.dispatch('app/setVerificationType', 'phone')
      //   } else {
      //     this.$emit('setLoading', true)
      //     useJwt.callUser(this.user.id)
      //         .then(response => {
      //           showNotify(this.$toast, 'Arama başarılı', 'success')
      //
      //           gtmTrackNewEvent('call', {
      //             category: 'Profil',
      //             action: 'Call',
      //             label: this.user.id
      //           })
      //         })
      //         .catch(error => {
      //           const { response } = error
      //           throwDefaultError(this.$toast, error)
      //           if (response && response.data?.header?.error?.errorCode == 215) {
      //             this.$store.dispatch('app/setModalAddPhoneNumberShowDiamond', false)
      //             this.$store.dispatch('app/setModalAddPhoneNumber', true)
      //             this.$store.dispatch('app/setVerificationType', 'phone')
      //           }
      //         })
      //         .finally(() => { this.$emit('setLoading', false) })
      //   }
      // }
    },
    checkBadge(level) {
      if (!this.user) return true

      const userBadgeLevel = this.user.profile?.userBadgeLevel ? this.user.profile?.userBadgeLevel.toLowerCase() : ''

      if (level === 'member') {
        if (['boho_member', 'bronze_member', 'silver_member', 'gold_member'].includes(userBadgeLevel))
          return false
      } else if (level === 'bronze') {
        if (['bronze_member', 'silver_member', 'gold_member'].includes(userBadgeLevel))
          return false
      } else if (level === 'silver') {
        if (['silver_member', 'gold_member'].includes(userBadgeLevel))
          return false
      } else if (level === 'gold') {
        if (['gold_member'].includes(userBadgeLevel))
          return false
      }

      return true
    },
    getGifts(showLoading = false) {
      if (showLoading) {
        this.loading = true
      }
      useJwt.getGifts()
          .then(response => {
            this.gifts = response.data.body
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => {
            this.loading = false
          })
    },
    getMultimediaCredits() {
      useJwt.getMultimediaCredits()
          .then(response => {
            this.multimediaCredits = response.data.body
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
    },
    getImgGift(name) {
      return require('@/assets/images/gifts/' + name)
    },
    getMe() {
      useJwt.getMe()
          .then(response => {
            store.dispatch('auth/updateUser', response.data.body)
          })

    },
    gtmGoChat() {
      gtmTrackNewEvent('chatclick', {
        category: 'Profil',
        action: 'ChatClick',
        label: this.user.id
      })
    },
    hideGiftModal(showChooseAnotherGift = false) {
      this.selectedGift = null
      this.giftModal = false
      this.showGifts = false

      if (showChooseAnotherGift) this.chooseGiftModal = true
    },
    like() {
      this.$emit('setLoading', true)
      useJwt.addFavoriteUser(this.user.id)
          .then(response => {
            showNotify(this.$toast, this.$t('Liking successful'), 'success')
            this.$emit('setUser', {
              ...this.user,
              fav: true
            })

            gtmTrackNewEvent('addfavorite', {
              category: 'Profil',
              action: 'AddFavorite',
              label: this.user.id
            })
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.$emit('setLoading', false) })
    },
    numberFormat(value) {
      return numberFormat(value)
    },
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    },
    reportUser() {
      this.$refs.reportForm.validate().then(success => {
        if (success) {
          this.$emit('setLoading', true)
          this.loading = true
          const data = {
            reportee: this.$store.getters['auth/userName'],
            reporteeEmail: this.$store.getters['auth/userEmail'],
            reported: this.user.userName,
            reason: this.report.reason,
            description: this.report.description,
          }
          useJwt.reportUser(data)
              .then(response => {
                showNotify(this.$toast, this.$t('Reporting user successful'), 'success')
                this.modalReportUser = false
                this.report = {
                  reason: '',
                  description: '',
                }

                gtmTrackNewEvent('reportuser', {
                  category: 'Profil',
                  action: 'ReportUser',
                  label: this.user.id
                })
              })
              .catch(error => {
                throwDefaultError(this.$toast, error)
              })
              .finally(() => {
                this.$emit('setLoading', false)
                this.loading = false
              })
        }
      })
    },
    showPrivateImages () {
      this.privateImagesShowStatus = true
      // const viewer = this.$el.querySelector('.private-images').$viewer
      // viewer.show()
    },
    showPrivateVideos () {
      this.privateVideosShowStatus = true
      // const viewer = this.$el.querySelector('.private-videos').$viewer
      // viewer.show()
    },
    showReportModal() {
      this.modalReportUser = true

      gtmTrackNewEvent('reportclick', {
        category: 'Profil',
        action: 'ReportClick',
        label: this.user.id
      })
    },
    sendGift(gift) {
      this.loading = true
      this.$emit('setLoading', true)
      const data = {
        productId: gift.productId,
        targetUserUuid: this.user.id,
      }
      useJwt.sendGift(data)
          .then(response => {
            showNotify(this.$toast, this.$t('Gift sending successful'), 'success')
            this.hideGiftModal()
            this.getMe()

            gtmTrackNewEvent('giftsent', {
              category: 'Profil',
              action: 'GiftSent',
              label: this.user.id
            })
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => {
            this.loading = false
            this.$emit('setLoading', false)
          })
    },
    showGiftModal(gift) {
      this.selectedGift = gift
      this.showGifts = false
      this.chooseGiftModal = false
      this.giftModal = true

      gtmTrackNewEvent('giftclick', {
        category: 'Profil',
        action: 'Giftclick',
        label: this.user.id
      })
    },
    unBlockUser() {
      this.$emit('setLoading', true)
      useJwt.unBlockUser(this.user.id)
          .then(response => {
            showNotify(this.$toast, this.$t('Unblocking user successful'), 'success')
            // this.$emit('setUser', {
            //   ...this.user,
            //   fav: true
            // })

            gtmTrackNewEvent('unblockuser', {
              category: 'Profil',
              action: 'UnblockUser',
              label: this.user.id
            })
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.$emit('setLoading', false) })
    },
    unlike() {
      this.$emit('setLoading', true)
      useJwt.removeFavoriteUser(this.user.id)
          .then(response => {
            showNotify(this.$toast, this.$t('Unliking successful'), 'success')
            this.$emit('setUser', {
              ...this.user,
              fav: false
            })

            gtmTrackNewEvent('removefavorite', {
              category: 'Profil',
              action: 'RemoveFavorite',
              label: this.user.id
            })
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.$emit('setLoading', false) })
    },
    videoCall() {
      this.$store.dispatch('app/setModalCallType', 'video')
      this.$store.dispatch('app/setModalCall', false)
      setTimeout(() => {
        this.$store.dispatch('app/setModalCallUser', this.user)
        this.$store.dispatch('app/setModalCall', true)
      }, 33)
    },
  }
}
</script>

<style lang="scss">
#user-profile {
  .gallery-edit-icon {
    position: absolute;
    z-index: 999;
    bottom: 24px;
    right: 24px;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    padding: 6px;
  }

  .card {
    background-color: #F5F8FD;
    border-radius: 24px;
  }

  .title {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    color: #000;
  }

  #user-profile-carousel {
    img {
      border-radius: 24px;
    }
  }

  .user-information {

    .name-location {
      .location {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #F9447A;
      }
    }

    .actions {
      display: flex;
      justify-content: end;
      align-items: start;
      margin-top: 6px;

      @media (max-width: 1199px) {
        justify-content: start;
      }

      div {
        background: #FFFFFF;
        box-shadow: 0 1px 8px #E8EDF4;
        border-radius: 50%;
        padding: 8px;
        margin-right: 12px;
        cursor: pointer;
      }
    }
  }

  .online-status {
    margin-top: 4px;
  }

  .about-me {
    margin-top: 16px;
    background: #FFFFFF;
    box-shadow: 0 1px 8px #E8EDF4;
    border-radius: 12px;
    font-weight: 300;
    font-size: 14px;
    line-height: 25px;
    color: #3D3D3D;
    padding: 16px;

    p {
      margin-bottom: 6px;
    }

    .head {
      color: #000000;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .link {
      color: #F9447A;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .images, .videos {
    margin-top: 20px;

    img {
      border-radius: 12px;
      cursor: zoom-in;
    }

    .plus {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #F9447A;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        padding: 16px;
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;
      }
    }

    .private-video-play-icon {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: zoom-in;
    }
  }

  .user-level {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-top: 12px;

    & > div {
      display: flex;
      justify-content: end;
      align-items: center;
      flex-direction: column;
      width: 25%;
      gap: 4px;
      height: 92px;

      div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        width: 100%;
      }

      &.user-level-bronze .bar {
        background: linear-gradient(to right, #CB6A34 0%, #F1AF8C 100%);
      }
      &.user-level-gold .bar {
        background: linear-gradient(to right, #D68E22 0%, #FFD75C 100%);
      }
      &.user-level-member .bar {
        background: linear-gradient(to right, #743BC5 0%, #DC85FF 100%);
      }
      &.user-level-silver .bar {
        background: linear-gradient(to right, #9A9A9A 0%, #D9D7DF 100%);
      }

      &.user-level-bronze .text {
        color: #CB6A34;
      }
      &.user-level-gold .text {
        color: #D68E22;
      }
      &.user-level-member .text {
        color: #743BC5;
      }
      &.user-level-silver .text {
        color: #212121;
      }

      &.passive {
        filter: grayscale(1);
        -webkit-filter: grayscale(1);

        &.user-level-silver .text {
          color: #7e7d7d;
        }
      }

      .bar {
        display: block;
        width: 100%;
        height: 8px;
      }

      .text {
        font-size: 8px;
        height: 26px;
        text-align: center;
        font-weight: bold;
      }

      &:first-of-type {
        .bar {
          border-bottom-left-radius: 12px;
          border-top-left-radius: 12px;
        }
      }
      &:last-of-type {
        .bar {
          border-bottom-right-radius: 12px;
          border-top-right-radius: 12px;
        }
      }
    }
  }
}
.modal-private-video {
  .modal-content {
    background-color: #fff;
  }
  .modal-body {
    padding: 12px !important;
    display: flex;
    justify-content: center;
  }
}
</style>
